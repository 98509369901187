module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-37968445-2","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aashutosh Rathi | Dev Portfolio","short_name":"Aashutosh.Dev","start_url":"/","background_color":"#252525","theme_color":"#343b3f","display":"minimal-ui","icon":"src/images/pic.webp"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
